.waveform-loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .bar {
    background-color: #1DB954;
    margin: 0 2px;
    width: 5px;
    height: 20px;
    animation: loading 1.2s infinite;
  }
  
  .bar:nth-child(1) { animation-delay: 0.1s; }
  .bar:nth-child(2) { animation-delay: 0.2s; }
  .bar:nth-child(3) { animation-delay: 0.3s; }
  .bar:nth-child(4) { animation-delay: 0.4s; }
  .bar:nth-child(5) { animation-delay: 0.5s; }
  .bar:nth-child(6) { animation-delay: 0.6s; }
  .bar:nth-child(7) { animation-delay: 0.7s; }
  .bar:nth-child(8) { animation-delay: 0.8s; }
  
  @keyframes loading {
    0%, 100% {
      height: 20px;
    }
    
    50% {
      height: 40px;
    }
  }
  